import { configureStore } from "@reduxjs/toolkit";
import cultureReducer from "./culture";
import visitReducer from "./visit";
import eventReducer from "./event";
import artReducer from "./arts";
import bookingReducer from "./booking";
import feedbackReducer from "./feedback";

const store = configureStore({
  reducer: {
    culture: cultureReducer,
    visit: visitReducer,
    event: eventReducer,
    art: artReducer,
    bookings: bookingReducer,
    feedback: feedbackReducer,
  },
});

export default store;
