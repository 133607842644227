import Footer from "../Footer";
import Navbar from "../Navbar";

const Layout = (props) => {
  return (
    <div className="overflow-x-hidden bg-[#ffffff]">
      <div className="fixed w-full z-50">
        <Navbar />
      </div>
      <main className="w-full">{props.children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
