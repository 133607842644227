import React from "react";
import { NavLink } from "react-router-dom";
import Navbar from "./Navbar";

const NotFound = () => {
  return (
    <>
      <Navbar />
      <div
        className="flex items-center justify-center w-full lg:h-sreen h-[92vh] bg-white
    "
      >
        <div className="lg:px-40 py-20 bg-primary text-white my-6 rounded-md shadow-xl">
          <div className="flex flex-col items-center">
            <h1 className="font-bold text-9xl">404</h1>

            <h6 className="mb-2 text-2xl font-bold text-center md:text-3xl">
              <span className="text-red">Oops!</span> Page not found
            </h6>

            <p className="mb-8 text-center md:text-lg px-4 md:px-0">
              The page you’re looking for doesn’t exist.
            </p>

            <NavLink
              to={"/"}
              className="px-6 py-2 text-sm font-bold bg-primary-button"
            >
              Back home
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
