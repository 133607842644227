import { Routes, Route } from "react-router-dom";
import King from "../pages/King";
import Home from "../pages/Home";
import ArtsAndCraft from "../pages/ArtsAndCraft";
import NotFound from "../components/Notfound";
import Library from "../pages/Library";
import Bookings from "../components/Footer";
import Permanant from "../pages/exhibition/Permanant";
import ContactPage from "../pages/ContactPage";

const MainRoute = () => {
  return (
    <>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/king" exact element={<King />} />
        <Route path="/arts&craft" exact element={<ArtsAndCraft />} />
        <Route path="/library" exact element={<Library />} />
        <Route path="/contact" exact element={<ContactPage />} />
        <Route path="/booking" exact element={<Bookings />} />
        <Route path="/exhibition/permanent" exact element={<Permanant route={"permanant"} />} />
        <Route path="/exhibition/temporary" exact element={<Permanant route={"temporary"} />} />
        <Route path="*" exact element={<NotFound />} />
      </Routes>
    </>
  );
};

export default MainRoute;
