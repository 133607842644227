import React from "react";
import Layout from "../../components/layout/Layout";
import { useTranslation } from "react-i18next";
import Urugo from "../../assets/images/urugo.jpg";
import Umuganura from "../../assets/images/umuganura.png";
import Giramata from "../../assets/images/giramata.png";
import Imyemerere from "../../assets/images/tradition.jpg";

import ExihibitionHouse from "../../assets/images/exhibition.jpg";
import Imyambarire from "../../assets/images/imyambarire.png";
import IbiganiroSite from "../../assets/images/ibiganiroSite.png";
import GiftShop from "../../assets/images/giftshop.png";

const Permanant = (props) => {
  const { t } = useTranslation();

  console.log("pathname", props?.route)


  return (
    <Layout>
      <div className="exhibition w-full flex flex-col justify-center grow items-center pt-44 h-3/4"></div>
      {props?.route === "permanant" && <>
        <div className="text-center flex mt-16 items-center mx-auto justify-center">
          <h1 className="CustomtextSizeTitle font-medium capitalize">
            {t("permTitle")}
          </h1>
        </div>
        <div className="max-w-7xl w-full m-auto my-20 space-y-20">
          <div className="flex lg:flex-row flex-col-reverse lg:justify-between">
            <img
              className="lg:w-[700px] lg:h-[500px] object-cover"
              src={Urugo}
              alt=""
            />
            <div className="pl-6">
              <p className="mb-4 text-justify mx-4 md:mx-0 text-lg ">
                <span className="CustomtextSizeSubTitle font-lexend text-xl">
                  {t("PermanentTitle1")}
                </span>{" "}
                <h1 className="font-helv font-medium CustomtextSize">
                  {t("permanentContent1")}
                </h1>
              </p>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col-reverse lg:justify-between">
            <div className="pr-6">
              <p className="mb-4 text-justify mx-4 md:mx-0 text-lg ">
                <span className="CustomtextSizeSubTitle font-lexend text-xl">
                  {t("PermanentTitle2")}
                </span>{" "}
                <h1 className="font-helv font-medium CustomtextSize">
                  {t("permanentContent2")}
                </h1>
              </p>
            </div>
            <img
              className="lg:w-[700px] lg:h-[500px] object-cover"
              src={Umuganura}
              alt=""
            />
          </div>
          <div className="flex lg:flex-row flex-col-reverse lg:justify-between">
            <img
              className="lg:w-[700px] lg:h-[500px] object-cover"
              src={Imyemerere}
              alt=""
            />
            <div className="pl-6">
              <p className="mb-4 text-justify mx-4 md:mx-0 text-lg ">
                <span className="CustomtextSizeSubTitle font-lexend text-xl">
                  {t("PermanentTitle3")}
                </span>{" "}
                <h1 className="font-helv font-medium CustomtextSize">
                  {t("permanentContent3")}
                </h1>
              </p>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col-reverse lg:justify-between">
            <div className="pr-6">
              <p className="mb-4 text-justify mx-4 md:mx-0 text-lg ">
                <span className="CustomtextSizeSubTitle font-lexend text-xl">
                  {t("PermanentTitle4")}
                </span>{" "}
                <h1 className="font-helv font-medium CustomtextSize">
                  {t("permanentContent4")}
                </h1>
              </p>
            </div>
            <img
              className="lg:w-[700px] lg:h-[500px] object-cover"
              src={Giramata}
              alt=""
            />
          </div>
        </div>
      </>}
      {props?.route === "temporary" && <>
        <div className="text-center flex mt-16 items-center mx-auto justify-center">
          <h1 className="CustomtextSizeTitle font-medium capitalize">
            {t("tempTitle")}
          </h1>
        </div>
        <div className="max-w-7xl w-full m-auto my-20 space-y-20">
          <div className="flex lg:flex-row flex-col-reverse lg:justify-between">
            <img
              className="lg:w-[700px] lg:h-[500px] object-cover"
              src={ExihibitionHouse}
              alt=""
            />
            <div className="pl-6">
              <p className="mb-4 text-justify mx-4 md:mx-0 text-lg ">
                <span className="CustomtextSizeSubTitle font-lexend text-xl">
                  {t("TemporaryTitle1")}
                </span>{" "}
                <h1 className="font-helv font-medium CustomtextSize">
                  {t("TemporaryContent1")}
                </h1>
              </p>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col-reverse lg:justify-between">
            <div className="pr-6">
              <p className="mb-4 text-justify mx-4 md:mx-0 text-lg ">
                <span className="CustomtextSizeSubTitle font-lexend text-xl">
                  {t("TemporaryTitle2")}
                </span>{" "}
                <h1 className="font-helv font-medium CustomtextSize">
                  {t("TemporaryContent2")}
                </h1>
              </p>
            </div>
            <img
              className="lg:w-[700px] lg:h-[500px] object-cover"
              src={Imyambarire}
              alt=""
            />
          </div>
          <div className="flex lg:flex-row flex-col-reverse lg:justify-between">
            <img
              className="lg:w-[700px] lg:h-[500px] object-cover"
              src={IbiganiroSite}
              alt=""
            />
            <div className="pl-6">
              <p className="mb-4 text-justify mx-4 md:mx-0 text-lg ">
                <span className="CustomtextSizeSubTitle font-lexend text-xl">
                  {t("TemporaryTitle3")}
                </span>{" "}
                <h1 className="font-helv font-medium CustomtextSize">
                  {t("TemporaryContent3")}
                </h1>
              </p>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col-reverse lg:justify-between">
            <div className="pr-6">
              <p className="mb-4 text-justify mx-4 md:mx-0 text-lg ">
                <span className="CustomtextSizeSubTitle font-lexend text-xl">
                  {t("TemporaryTitle4")}
                </span>{" "}
                <h1 className="font-helv font-medium CustomtextSize">
                  {t("TemporaryContent4")}
                </h1>
              </p>
            </div>
            <img
              className="lg:w-[700px] lg:h-[500px] object-cover"
              src={GiftShop}
              alt=""
            />
          </div>
        </div>
      </>}
    </Layout>
  );
};

export default Permanant;
