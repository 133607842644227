import React from "react";
import Layout from "../components/layout/Layout";

const Library = () => {
  return (
    <Layout>
      <div className="library w-full flex flex-col justify-center grow items-center pt-44 h-screen"></div>
      <div className="max-w-7xl w-full m-auto my-20 bg-white ">
        <div className="text-center flex mt-16 mb-10 items-center mx-auto justify-center">
          <h1 className="CustomtextSizeTitle font-semibold capitalize">
            Ikirenga Cultural Centre (ICC) Library
          </h1>
        </div>

        <p className="CustomtextSize mb-4 text-justify mx-4 md:mx-0 text-lg p-4 font-helv">
          <span className="font-lexend CustomtextSizeSubTitle">
            {" "}
            Welcome to our magical book reading library for kids!
          </span>{" "}
          Imagination knows no limits here, and we're thrilled to have you join
          us. Step into a world of wonder and embark on incredible literary
          journeys.
          Our library is filled with a wide variety of books curated
          specifically for young minds. From colorful picture books to
          captivating chapter books, there's a treasure trove of stories waiting
          to be discovered. Whether you're a budding reader or a seasoned
          bookworm, we have tales for all ages and interests. <br /> <br />{" "}
          Explore our shelves and find stories of brave adventurers, friendly
          animals, whimsical fairies, and mythical creatures. Let your
          imagination soar as you dive into fantastical worlds, solve mysteries,
          and{" "}
          <span className="font-lexend CustomtextSizeSubTitle">
            Learn valuable life lessons through the power of storytelling
          </span>{" "}
          . Our friendly staff is here to guide you on your
          reading adventures. They're passionate about children's literature and
          are eager to recommend books that will capture your imagination and
          deepen your love for reading. Don't hesitate to ask for their
          assistance. In addition to our vast collection, we host
          exciting events and activities just for kids. Join us for engaging
          storytime sessions where talented storytellers bring tales to life. We
          also organize interactive workshops where you can explore arts and
          crafts, creative writing, and other fun-filled activities. <br />{" "}
          <br /> Our library is not only a place to read, but also a welcoming
          space to meet other young book enthusiasts. Make new friends, exchange
          recommendations, and share your love for stories. We believe that
          reading is best enjoyed together! Get ready for
          extraordinary adventures, unlock your imagination, and let the magic
          of books ignite your curiosity. Welcome to our book reading library
          for kids, where dreams take flight and the love for reading grows.
          Enjoy your time here, and may every page you turn bring you endless
          joy and inspiration!
        </p>
      </div>
    </Layout>
  );
};

export default Library;
