import React from 'react'
import Layout from '../components/layout/Layout'

const ContactPage = () => {
    return (
        <Layout>
            <div className="exhibition w-full flex flex-col justify-center grow items-center pt-44 h-3/4"></div>
            <section className="bg-[#ffffff]" id="contact">
                <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8 lg:pb-20">
                    <div className="mb-4">
                        <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">

                            <h2
                                className="CustomtextSizeTitle font-bold capitalize">
                                Get in Touch
                            </h2>
                        </div>
                    </div>
                    <div className="flex items-stretch justify-center">
                        <div className="grid md:grid-cols-2">
                            <div className="h-full pr-6">
                                <p className="mt-3 mb-12 text-lg CustomtextSize">
                                    We aim to answer all messages on the same day that we receive them. Our friendly multilingual team will be very pleased to hear from you!
                                </p>
                                <ul className="mb-6 md:mb-0">
                                    <li className="flex">
                                        <div className="flex h-10 w-10 items-center justify-center rounded bg-primary text-gray-50">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" className="h-6 w-6">
                                                <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                                                <path
                                                    d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z">
                                                </path>
                                            </svg>
                                        </div>
                                        <div className="ml-4 mb-4">
                                            <h3 className="mb-2 text-lg font-semibold leading-6 CustomtextSizeSubTitle">Our Address
                                            </h3>
                                            <p className="CustomtextSize">Kigali-Musanze Road</p>
                                            <p className="CustomtextSize">Rulindo/Kirenge center</p>
                                        </div>
                                    </li>
                                    <li className="flex">
                                        <div className="flex h-10 w-10 items-center justify-center rounded bg-primary text-gray-50">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" className="h-6 w-6">
                                                <path
                                                    d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2">
                                                </path>
                                                <path d="M15 7a2 2 0 0 1 2 2"></path>
                                                <path d="M15 3a6 6 0 0 1 6 6"></path>
                                            </svg>
                                        </div>
                                        <div className="ml-4 mb-4">
                                            <h3 className="mb-2 text-lg font-semibold leading-6 CustomtextSizeSubTitle">Contact
                                            </h3>
                                            <p className="CustomtextSize">Mobile: (+250) 788645269</p>
                                            <p className="CustomtextSize">Mail: info@ikirengaculturalcentre.com</p>
                                        </div>
                                    </li>
                                    <li className="flex">
                                        <div className="flex h-10 w-10 items-center justify-center rounded bg-primary text-gray-50">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" className="h-6 w-6">
                                                <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                                                <path d="M12 7v5l3 3"></path>
                                            </svg>
                                        </div>
                                        <div className="ml-4 mb-4">
                                            <h3 className="mb-2 text-lg font-semibold leading-6 CustomtextSizeSubTitle">Working
                                                hours</h3>
                                            <p className="CustomtextSize">Monday - Friday: 09:00 - 17:00</p>
                                            <p className="CustomtextSize">Saturday &amp; Sunday: 09:00 - 14:00</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="card h-fit max-w-6xl p-5 md:px-12" id="form">
                                <h2 className="CustomtextSizeSubTitle font-bold capitalize mb-4">Send Us Message</h2>
                                <form>
                                    <div className="mb-6">
                                        <div className="mx-0 mb-1 sm:mb-4">
                                            <div className="mx-0 mb-1 sm:mb-4">
                                                <label for="name" className="pb-1 text-xs uppercase tracking-wider">
                                                </label>
                                                <input type="text" id="name" autocomplete="given-name" placeholder="Your names" className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md sm:mb-0 outline-none" name="name" />
                                            </div>
                                            <div className="mx-0 mb-1 sm:mb-4">
                                                <label for="email" className="pb-1 text-xs uppercase tracking-wider"></label>
                                                <input type="email" id="email" autocomplete="email" placeholder="Your email address" className="outline-none mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md sm:mb-0" name="email" />
                                            </div>
                                            <div className="mx-0 mb-1 sm:mb-4">
                                                <label for="name" className="pb-1 text-xs uppercase tracking-wider">
                                                </label>
                                                <input type="text" id="name" autocomplete="given-name" placeholder="Subject" className="outline-none mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md sm:mb-0" name="name" />
                                            </div>
                                        </div>
                                        <div className="mx-0 mb-1 sm:mb-4">
                                            <label for="textarea" className="pb-1 text-xs uppercase tracking-wider"></label>
                                            <textarea id="textarea" name="textarea" cols="30" rows="5" placeholder="Write your message..." className=" outline-none mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md sm:mb-0"></textarea>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button className="w-full bg-primary text-white px-6 py-3 font-xl rounded-md sm:mb-0">Send Message</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default ContactPage
