import React, { useEffect } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "./visit.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllVisitDatasAction } from "../store/visit/actions";
import Carousel from "better-react-carousel";
import { getAllEventDatasAction } from "../store/event/actions";
import { useNavigate } from "react-router-dom";

const Visit = () => {
  const { visit, event } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    getAllVisitDatasAction("?isActive=true")(dispatch);
    getAllEventDatasAction("?isActive=true")(dispatch);
  }, [dispatch]);

  const combineArraysAndReturnImages = (array1, array2) => {
    const imagesArray = [];

    array1?.forEach((item) => {
      if (item.image) {
        imagesArray.push({ image: item.image });
      }
    });

    array2?.forEach((item) => {
      if (item.image) {
        imagesArray.push({ image: item.image });
      }
    });

    return imagesArray;
  };

  const navigate = useNavigate();

  const goNext = () => {
    navigate("/contact");
  };

  return (
    <div>
      <hr className="h-0.5 mt-2 border-none  bg-gray-100" />
      <div className="text-center flex my-10 items-center mx-auto justify-center">
        <h1 className="CustomtextSizeTitle font-bold capitalize">
          conferences
        </h1>
      </div>
      <div className="mb-10">
        <p className="font-helv font-medium CustomtextSize mx-4">
          Welcome to{" "}
          <span className="font-lexend text-[16px]">
            Ikirenga Cultural Centre
          </span>
          , where cultural wonders await you! Immerse yourself in the vibrant
          tapestry of our rich heritage, explore enchanting landmarks, and
          discover the captivating essence of our community. We invite you to
          embark on a journey that will unveil the hidden gems, picturesque
          landscapes, and warm hospitality that make{" "}
          <span className="font-lexend text-[16px]">IKIRENGA</span> truly
          unforgettable. Start your adventure and let us guide you through an
          unforgettable experience of culture, history, and endless exploration.
          Welcome!
          <br />
          <span className="font-lexend text-[16px]">
            {" "}
            Planning a Visit, We Can Help.{" "}
          </span>
        </p>
        <buttom
          onClick={goNext}
          className="hidden mx-4 md:flex w-56 cursor-pointer text-center text-lg justify-center bg-primary font-Inria p-2.5 rounded-md text-white mt-6"
        >
          {" "}
          Book Your Seat Now
        </buttom>
      </div>
      <div>
        <Carousel cols={3} rows={2} gap={10} loop={true} autoplay={5000}>
          {combineArraysAndReturnImages(
            visit?.all?.data,
            event?.all?.data
          )?.map((el) => (
            <Carousel.Item key={el.id}>
              <img
                src={el.image}
                alt=""
                width="100%"
                className="rounded-lg xw-[300px] h-[300px]"
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Visit;
