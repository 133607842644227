import { myArtsActions } from ".";
import { getAllArtsDateService } from "./services";

export const getAllArtDatasAction = (query) => {
  return async (dispatch) => {
    try {
      dispatch(myArtsActions.setIsFetching(true));
      const res = await getAllArtsDateService(query);
      if (res?.status === 200) {
        console.log("::::INNN", res);
        dispatch(myArtsActions.setAll(res));
        dispatch(myArtsActions.setIsFetching(false));
      }
      dispatch(myArtsActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
