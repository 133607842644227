import { myVisitActions } from ".";
import { getAllVisitDateService } from "./services";

export const getAllVisitDatasAction = (query) => {
  return async (dispatch) => {
    try {
      dispatch(myVisitActions.setIsFetching(true));
      const res = await getAllVisitDateService(query);
      if (res?.status === 200) {
        dispatch(myVisitActions.setAll(res));
        dispatch(myVisitActions.setIsFetching(false));
      }
      dispatch(myVisitActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
