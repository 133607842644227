import { Col, DatePicker, Form, Input, Row } from "antd";
import moment from "moment";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { sendBookingRequestAction } from "../../store/booking/actions";
import { useState } from "react";

const { TextArea } = Input;

const BookingForm = () => {
  const { bookings } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedDate, setSelectedDate] = useState("");

  const onFinish = async (values) => {
    await sendBookingRequestAction({
      ...values,
      date: selectedDate,
      isNotify: true,
      notification: {
        action: "Booking Request",
        role: ["user"],
        message: `name:${values?.name}, message:${values?.message}`,
        title: `Booking`,
      },
    })(dispatch);
    setSelectedDate("");
    form.resetFields();
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setSelectedDate(dateString);
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      style={{ color: "white" }}
    >
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            name={"name"}
            label={<span style={{ color: "white" }}>Names/Group</span>}
            rules={[
              {
                required: true,
                message: "Name is required",
              },
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={"email"}
            label={<span style={{ color: "white" }}>Email</span>}
          >
            <Input type="email" placeholder="Your Email" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={"phone"}
            label={<span style={{ color: "white" }}>Telephone</span>}
            rules={[
              {
                required: true,
                message: "Mobile is required",
              },
            ]}
          >
            <Input type="number" placeholder="Telephone number" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={"nationality"}
            label={<span style={{ color: "white" }}>Nationality</span>}
          >
            <Input placeholder="Country" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={"number"}
            label={<span style={{ color: "white" }}>Number</span>}
            rules={[
              {
                required: true,
                message: "number is required",
              },
            ]}
          >
            <Input type="number" placeholder="No of People" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={<span style={{ color: "white" }}>Date</span>}>
            <DatePicker
              disabledDate={disabledDate}
              onChange={onChange}
              required
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name={"message"}>
            <TextArea
              placeholder="Comment/ Message"
              autoSize={{
                minRows: 2,
                maxRows: 6,
              }}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item className="flex justify-end">
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ minWidth: "15%" }}
              endIcon={<SendIcon />}
              loading={bookings?.isFetching}
            >
              Send Now
            </LoadingButton>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default BookingForm;
