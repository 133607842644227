import React, { useEffect } from "react";
import videoBg from "../assets/video/bgVideo.mp4";
import Layout from "../components/layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getAllArtDatasAction } from "../store/arts/actions";
import PhotoAlbum from "react-photo-album";

const Services = () => {
  const { art } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    getAllArtDatasAction("?isActive=true")(dispatch);
  }, [dispatch]);

  const photosToDisplay = art?.all?.data.map((item, index) => ({
    src: item.image,
    alt: `Photo ${index + 1}`,
    key: index,
    width: 600,
    height: 500
  }));

  return (
    <Layout>
      <div className="">
        <header className="relative flex items-center justify-center h-screen overflow-hidden">
          <div className="relative z-20 p-3 text-xl text-white bg-primary bg-opacity-50 rounded-xl CustomtextSizeTitle">
            Gift Shop
          </div>
          <video
            autoPlay
            loop
            muted
            src={videoBg}
            className="absolute z-10 w-auto min-w-full min-h-full my-20 object-cover max-w-none"
          />
        </header>
      </div>

      <div className="text-center flex mt-16 items-center mx-auto justify-center">
        <h1 className="CustomtextSizeTitle font-bold capitalize">
          Arts and Craft
        </h1>
      </div>
      <hr className="lg:w-32 w-24 lg:mb-10 h-1 border-none text-center items-center mx-auto bg-primary" />

      <div className="max-w-7xl m-auto mt-5 mb-20">
        <p className="mb-4 text-justify mx-4 md:mx-0 text-lg font-helv CustomtextSize">
          Welcome to our vibrant world of art and craft, where creativity meets
          culture! As you embark on your tourism adventure, allow us to
          introduce you to the rich artistic heritage of our destination.
          Immerse yourself in the colors, textures, and stories that our local
          artisans weave into their masterpieces. From intricate handcrafted
          jewelry to exquisite pottery and breathtaking paintings, our art and
          craft scene reflects the essence of our culture and traditions. As you
          explore our galleries, studios, and craft markets, you'll witness the
          skill and passion that goes into each creation.
          <br />
          <br /> Whether you choose to observe or participate, art and craft
          offer you a unique lens to connect with our community and understand
          our history. Discover hidden gems, meet talented artisans, and even
          try your hand at creating your own masterpiece during interactive
          workshops.
          <br />
          <br /> Let art and craft be your guide as you delve into the heart of
          our destination, unlocking its beauty and immersing yourself in its
          soul. Get ready to be captivated by the artistic treasures that await
          you at every turn. Welcome to a world of inspiration and creativity!
        </p>
      </div>

      <div className="max-w-7xl m-auto mt-8 mb-20">
        <div className="overflow-x-hidden z-0 h-auto gap-2 md:px-20 items-center justify-center w-full mb-5">
          <PhotoAlbum layout="rows" photos={photosToDisplay} />
        </div>
      </div>
    </Layout>
  );
};

export default Services;
