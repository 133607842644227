import Image from "../assets/images/homepageimage.jpg";
const Home = () => {
  return (
    <>
      <div className="exhibition w-full flex flex-col justify-center grow items-center pt-44 h-screen"></div>
      <div className="text-center flex mt-16 items-center mx-auto justify-center">
        <h1 className="CustomtextSizeTitle font-bold capitalize">
          Ikirenga Cultural Centre
        </h1>
      </div>
      <div className="max-w-7xl m-auto mt-20">
        <div className="flex lg:flex-row flex-col-reverse lg:justify-between">
          <img
            className="lg:w-[700px] lg:h-[500px] object-cover"
            src={Image}
            alt=""
          />
          <div className="pl-4">
            <p className="mb-4 text-justify mx-4 md:mx-0 text-lg ">
              <span className="CustomtextSizeSubTitle font-lexend text-xl">
                Ikirenga Cultural Centre (ICC)
              </span>{" "}
              <h1 className="font-helv font-medium CustomtextSize">
                Is{" "}
                <a
                  href="https://www.google.com/maps/place/Ikirenga+Cultural+Centre/@-1.7964289,29.9291916,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipNNbN71ffdzPfgDOe463tSB6QV5zA9LAwJXP2bO!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipNNbN71ffdzPfgDOe463tSB6QV5zA9LAwJXP2bO%3Dw114-h86-k-no!7i4032!8i3024!4m7!3m6!1s0x19dc976988cbcd2b:0x4f92d1eb0c4972a6!8m2!3d-1.796429!4d29.9291915!10e5!16s%2Fg%2F11fn25zbd6"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-500 underline"
                >
                  Located
                </a>{" "}
                at Kirenge cya Ruganzu, literally the Foot-of- Ruganzu, in Gako
                Cell, Rusiga Sector, Rulindo District in Northern Province,
                precisely in 25 kilometers from Kigali along Kigali-Musanze main
                road. Launched on 27 September 2015, to conduct training in
                arts, crafts and dances; to showcase regional and national
                history and culture via accessible and well documented
                historical and cultural sites located in Rulindo District.{" "}
                <br />
                The Center is surrounded by beautiful garden, different
                exhibition buildings on the economy, history, culture and way of
                life of Rwandans in different times.
              </h1>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
