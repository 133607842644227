import { notification } from "antd";
import React from "react";

const KingWar = () => {
  const openNotification = () => {
    notification.open({
      message: "Visit us at Ikirenga Cultural Centre",
      description:
        "For more history of ruganzu II ndoli, please take your time and find more at ikirenga",
      onClick: () => {
        console.log("Notification Clicked!");
      },
    });
  };
  return (
    <div className="bg-slate-200 my-12 py-10">
      <h1 className="text-2xl font-bold mt-3 text-left mx-4 md:mx-0 md:text-center mb-10 font-lexend">
        WARS UNDER THE RULE OF RUGANZU II NDOLI
      </h1>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-4 px-4 md:px-10 lg:px-32">
        <div>
          <h5 className="text-xl md:text-lg font-semibold mt-3 capitalize font-lexend">
            The attack that resulted in the death of Byinshi
          </h5>
          <hr className="w-64 border-2 border-blue-500 mb-6" />
          <span className="text-lg mx-4 md:mx-0 font-medium mb-2 text-justify font-helv">
            The first attack that Ruganzu led was against Byinshi, who was
            killed at Bweramvura, near Kabuye (Jabana-Gasabo sector). His
            surviving loyalists scattered and fled to the Congo. These people
            took the name of Banyabyinshi.
          </span>
        </div>
        <div>
          <h5 className="text-xl md:text-lg font-semibold mt-3 capitalize font-lexend">
            Attack on Bunyabungo
          </h5>
          <hr className="w-64 border-2 border-blue-500 mb-6" />
          <span className="text-lg mx-4 md:mx-0 font-medium mb-2 text-justify font-helv">
            Ruganzu then took revenge on his father Ndahiro II Cyamatare. Before
            attacking Bunyabungo, he first reconquered all the eastern
            principalities of Lake Kivu and the Ijwi island. The Banyabungo felt
            that they were not in a position to fight or surrender to Ruganzu
            decided to flee.
          </span>
        </div>
        <div>
          <h5 className="text-xl md:text-lg font-semibold mt-3 capitalize font-lexend">
            {" "}
            Attacks in southern Rwanda
          </h5>
          <hr className="w-64 border-2 border-blue-500 mb-6" />
          <span className="text-lg mx-4 md:mx-0 font-medium mb-2 text-justify font-helv">
            Ruganzu then took over the former principalities between Nduga and
            Burundi: Bunyambiriri, Bwanamukari, Burwi and Ndora.
          </span>
        </div>

        <div>
          <h5 className="text-xl md:text-lg font-semibold mt-3 capitalize font-lexend">
            {" "}
            The invasion against Bunyambiriri
          </h5>
          <hr className="w-64 border-2 border-blue-500 mb-6" />
          <span className="text-lg mx-4 md:mx-0 font-medium mb-2 text-justify font-helv">
            Bunyambiriri was located in the north of the present Nyamagabe
            district. At that time it was ruled by Gisurere, and its capital was
            called &#39;Suti ya Banega&#39; near Musange. The kings of Suti were
            known to be insect repellents. Ruganzu attacked Gisurere and killed
            him, but he opted for a policy of good neighborliness with his
            descendants, because of their usefulness to the king&#39;s people.
            Gisurere&#39;s descendants got favor to continue ruling Bunyambiriri
            and to keep their royal names and the honors associated with that
            rank. In front of the King of Rwanda, they were considered as
            &quot;kinglets&quot;.
          </span>
        </div>

        <div>
          <h5 className="text-xl md:text-lg font-semibold mt-3 capitalize font-lexend">
            {" "}
            The conquest of Bunyambiriri
          </h5>
          <hr className="w-64 border-2 border-blue-500 mb-6" />
          <span className="text-lg mx-4 md:mx-0 font-medium mb-2 text-justify font-helv">
            During Ruganzu’s reign, Nyakarashi of Zivu ruled one part of
            Bwanamukari, and the other under the control of Mpandahande, who
            lived in Ruhande, first Ruganzu attacked and killed Nyakarashi, then
            followed Mpandahande &quot;to leave Ruhande once and for all&quot;.
          </span>
        </div>

        <div>
          <h5 className="text-xl md:text-lg font-semibold mt-3 capitalize font-lexend">
            {" "}
            Attack of Burwi
          </h5>
          <hr className="w-64 border-2 border-blue-500 mb-6" />
          <span className="text-lg mx-4 md:mx-0 font-medium mb-2 text-justify font-helv">
            Ruganzu then attacked Burwi, a remnant of the principality formerly
            ruled by the Barenge long before the rise of the Nyinginya dynasty
            (currently in Gisagara district). This principality was ruled by
            Nyaruzi son of Haramanga who was killed by Ruganzu at place known as
            &#39; Makindo ya Makwaza&#39;.
          </span>
        </div>
        <div>
          <h5 className="text-xl md:text-lg font-semibold mt-3 capitalize font-lexend">
            {" "}
            The conquest of Bugoyi
          </h5>
          <hr className="w-64 border-2 border-blue-500 mb-6" />
          <span className="text-lg mx-4 md:mx-0 font-medium mb-2 text-justify font-helv">
            This attack killed the kinglet named &quot;Kamima ka
            Kamirogosa&quot; who lived in the Ngabo forest, near Lake Kivu, in
            the present district of Rutsiro.
          </span>
        </div>
        <div>
          <h5 className="text-xl md:text-lg font-semibold mt-3 capitalize font-lexend">
            {" "}
            Attacks in the area surrounding Bwishaza
          </h5>
          <hr className="w-64 border-2 border-blue-500 mb-6" />
          <span className="text-lg mx-4 md:mx-0 font-medium mb-2 text-justify font-helv">
            Ruganzu directed his attacks towards the area around the former
            Bwishaza, annexed to Rwanda during the reign of his ancestor Yuhi II
            Gahima, who had conceived a plan - which could not be realized - to
            give back to Rwanda the neighboring regions, which are currently in
            the Democratic Republic of Congo.
          </span>
        </div>
      </div>
      <div className="mt-4 ml-4 md:ml-10 lg:ml-32">
        <buttom
          className="hidden md:flex w-36 text-center justify-center bg-primary-button-hover font-Inria p-2.5 rounded-md text-white"
          onClick={openNotification}
        >
          {" "}
          Seen More...
        </buttom>
      </div>
    </div>
  );
};

export default KingWar;
