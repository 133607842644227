import React from "react";
import Layout from "../components/layout/Layout";
import KingImage from "../assets/images/Ruganzu-Ndoli.jpg";
import { Button, notification } from "antd";
import KingWar from "../components/KingWar";

const King = () => {
  const openNotification = () => {
    notification.open({
      message: "Visit us at Ikirenga Cultural Centre",
      description:
        "For more history of ruganzu II ndoli, please take your time and find more at ikirenga",
      onClick: () => {
        console.log("Notification Clicked!");
      },
    });
  };
  return (
    <Layout>
      <div className="w-full flex flex-col justify-center grow items-center mt-4 md:mt-20 md:mb-12">
        <div>
          <img
            src={KingImage}
            alt="king ruganzu"
            className="object-cover mt-10 md:mt-0"
          />
          <h1 className="font-semibold uppercase px-0.5">
            monument of RUGANZU II NDOLI KING OF RWANDA (1510-1543)
          </h1>
        </div>
      </div>
      <div className="w-full lg:w-3/4 flex items-center justify-center md:mx-auto">
        <div className="md:w-4/5 px-4 mt-10 lg:mt-0">
          <h1 className="text-3xl md:text-4xl font-bold text-center mb-10 font-lexend">
            KING RUGANZU II NDOLI
          </h1>
          <div>
            <h1 className="text-2xl md:text-lg font-semibold mt-3 font-lexend">
              WHO IS RUGANZU II NDOLI?
            </h1>
            <hr className="w-64 border-2 border-blue-500 mb-6" />
            <span className="text-lg mx-4 md:mx-0 font-medium mb-2 text-justify font-helv">
              Ruganzu II Ndoli is the 18th king on the genealogic list of the
              Nyingiginya dynasty. He ruled Rwanda around 1510-1543. He is the
              son of King Ndahiro II Cyamatare and Nyabacuzi, of the Abakono
              clan. <br />
              <br /> Ruganzu is known as the most powerful king, as he succeeded
              his father Ndahiro II Cyamatare, who was assassinated with his all
              family by Nsibura I Nyebunga, the king of Bunyabungo (Congo), who
              invaded Rwanda with the help of Nzira, son of Muramira, the king
              of Bugara (on the shores of volcanoes) Ndoli survived the killings
              because his father had exiled him to his aunt Nyabunyana, who was
              married to King Karemera Ndagara of Karagwe. <br />
              <br />
              It was there that Ndoli took refuge (hiding), in a secret that was
              known only by his closest friends: Abaryankuna. The Banyabungo
              ruled Rwanda for 11 years, with the help of some of Ndoli&#39;s
              stepbrothers, such as Juru and his brother Bamara, who later ruled
              the then eastern part of Rwanda. <br />
              <br /> When Ndoli returned from the exile, he started the Rwandan
              liberation wars, with the help Abaryankuna and his comrades in
              arms who distinguished themselves as the heroes of all the times:
              IBISUMIZI. <br />
              <br />
            </span>
          </div>
          <div>
            <h1 className="text-2xl md:text-lg font-semibold mt-3 font-lexend">
              THE ENTHRONEMENT OF RUGANZU II NDOLI
            </h1>
            <hr className="w-64 border-2 border-blue-500 mb-6" />
            <span className="text-lg mx-4 md:mx-0 font-medium mb-2 text-justify font-helv">
              Abiru enthroned Ndoli in great secrecy with the royal name of
              Ruganzu II Ndoli. His enthronement took place in Gatsibo, the
              former home of King Gihanga, the founder of Rwanda kingdom. At
              that time, this region was in Ndorwa principality ruled by
              Abashambo clan. It was necessary for Ruganzu II Ndoli to have an
              emblematic drum to replace Rwoga, which Banyabungo plundered. The
              new drum took the name of Karinga. <br />
              <br />
              Nyamigezi, son of Minyaruko of Nyamikenke from the Busigi region,
              who was at the same time an esoteric code holder and rainmaker
              made it at Buberuka, in Cyungo sector of Rulindo district. <br />
              <br />
              In addition, as the king no longer had a mother, Abiru looked for
              a queen mother to rule with him. This one is Nyirarumaga
              originally from Gihogwe (Jali-Gasabo). From Basinga clan, she was
              crowned under the dynastic name of Nyiraruganzu II. Ruganzu
              organized an army, starting with the Ibisumizi militia, which he
              created himself, commanded by Muvunyi son of Karema, and other
              militias that Ndoli found in the country, which had been loyal
              such as ABARUHUJE, NYAKARE and NYANTANGO.
            </span>
          </div>
        </div>
      </div>
      <KingWar />
    </Layout>
  );
};

export default King;
