import { myCultureActions } from ".";
import { getAllCultureDateService } from "./services";

export const getAllCultureDatasAction = (query) => {
  return async (dispatch) => {
    try {
      dispatch(myCultureActions.setIsFetching(true));
      const res = await getAllCultureDateService(query);
      if (res?.status === 200) {
        dispatch(myCultureActions.setAll(res));
        dispatch(myCultureActions.setIsFetching(false));
      }
      dispatch(myCultureActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
