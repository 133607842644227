import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAllCultureDatasAction } from "../store/culture/actions";
import { Image } from "antd";
import Carousel from "better-react-carousel";

const Expore = () => {
  const { culture } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    getAllCultureDatasAction("?isActive=true")(dispatch);
  }, [dispatch]);

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="pb-6 pt-8 overflow-x-hidden mt-8">
      <hr className="h-[0.1px] mt-2 border-none  bg-primary" />
      <div className="text-center flex my-10 items-center mx-auto justify-center">
        <h1 className="CustomtextSizeTitle font-bold capitalize">
          Explore Our Culture
        </h1>
      </div>
      <div>
        <Carousel cols={2} rows={1} gap={10} loop={true} autoplay={5000}>
          {culture?.all?.data?.slice(0, 4)?.map((el) => (
            <Carousel.Item key={el.id}>
              <img
                src={el.image}
                alt=""
                width="100%"
                className="rounded-lg xw-[300px] h-[500px]"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />

              <div
                className={`transition-transform ease-in-out duration-300 transform ${
                  isHovered
                    ? "translate-x-2 translate-y-2 absolute bottom-3"
                    : "hidden"
                }`}
              >
                <span className="CustomtextSizeSubTitle text-[#ffff]">
                  {el.title}
                </span>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Expore;
