import "./OurTeam.css";
// import FacebookIcon from "@mui/icons-material/Facebook";
import theogen from "../../assets/photo/theogen.jpg";
import uwamahoro from "../../assets/photo/uwamahoro.png";
import kwizera from "../../assets/photo/kwizera.jpg";
import Ephrem from "../../assets/photo/ephrem.jpg";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import TwitterIcon from "@mui/icons-material/Twitter";

const OurTeam = () => {
  return (
    <div className="mt-14 mb-44">

      <hr className="h-0.5 mt-2 border-none  bg-gray-100" />
      <div className="text-center flex my-10 items-center mx-auto justify-center">
        <div>
          <h1 className="CustomtextSizeTitle font-bold capitalize">
            Meet our team
          </h1>
          <div className="divider"></div>

        </div>
      </div>

      <div className="w-full h-full bg-white px-4 md:px-10 py-6 flex flex-wrap mx-auto gap-4">
        <div className="bg-transparent border-2  rounded-md w-72 h-96 border-primary">
          <h1 className="text-center pt-4 text-xl text-gray-700 font-bold capitalize CustomtextSizeSubTitle">
            Managing director
          </h1>
          <div className="flex items-center flex-col mt-4 space-y-4">
            <img
              src={theogen}
              alt=""
              className="rounded-full object-cover w-32 h-32"
            />
            <div className="text-xl text-blue-400 font-bold">
              <h1>Theogene</h1>
              <h1 className="uppercase">Niyonshimira</h1>
            </div>
          </div>
          <div className="w-full mt-4 ">
            <div className="mx-4 p-2 bg-primary text-white flex flex-col space-y-1 rounded">
              <p>
                <PhoneIcon style={{ color: "white", fontSize: "18px" }} />{" "}
                <span>+250788645269</span>
              </p>
              <p>
                <EmailIcon style={{ color: "white", fontSize: "18px" }} />{" "}
                <span>nitheogene2@gmail.com</span>
              </p>
              <p>
                <TwitterIcon style={{ color: "white", fontSize: "18px" }} />{" "}
                <a
                  href="https://twitter.com/nitheogene1"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-transparent border-2  rounded-md w-72 h-96 border-primary">
          <h1 className="text-center pt-4 text-xl text-gray-700 font-bold capitalize CustomtextSizeSubTitle">
            Docent & Public Relation
          </h1>
          <div className="flex items-center flex-col mt-4 space-y-4">
            <img
              src={uwamahoro}
              alt=""
              className="rounded-full object-cover w-32 h-32"
            />
            <div className="text-xl text-blue-400 font-bold">
              <h1>Jeanne</h1>
              <h1 className="uppercase">Uwamahoro</h1>
            </div>
          </div>
          <div className="w-full mt-4">
            <div className="mx-4 p-2 bg-primary text-white flex flex-col space-y-1 rounded">
              <p>
                <PhoneIcon style={{ color: "white", fontSize: "18px" }} />{" "}
                <span>+250783374408</span>
              </p>
              <p>
                <EmailIcon style={{ color: "white", fontSize: "18px" }} />{" "}
                <span>januwamahoro@gmail.com</span>
              </p>
              <p>
                <TwitterIcon style={{ color: "white", fontSize: "18px" }} />{" "}
                <a
                  href="https://twitter.com/uwamahoro_jeanne"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter
                </a>
              </p>
            </div>
          </div>
        </div>
        {/* <div className="bg-transparent border-2  rounded-md w-72 h-96 border-primary">
          <h1 className="text-center pt-4 text-xl text-gray-700 font-bold capitalize CustomtextSizeSubTitle">
            Preparator
          </h1>
          <div className="flex items-center flex-col mt-4 space-y-4">
            <img
              src={kwizera}
              alt=""
              className="rounded-full object-cover w-32 h-32"
            />
            <div className="text-xl text-blue-400 font-bold">
              <h1>Omer</h1>
              <h1 className="uppercase">kwizera</h1>
            </div>
          </div>
          <div className="w-full mt-4">
            <div className="mx-4 p-2 bg-primary text-white flex flex-col space-y-1 rounded">
              <p>
                <PhoneIcon style={{ color: "white", fontSize: "18px" }} />{" "}
                <span>+250784212193</span>
              </p>
              <p>
                <EmailIcon style={{ color: "white", fontSize: "18px" }} />{" "}
                <span>omerballet@gmail.com</span>
              </p>
              <p>
                <TwitterIcon style={{ color: "white", fontSize: "18px" }} />{" "}
                <a
                  href="https://twitter.com/kwizeraomer"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter
                </a>
              </p>
            </div>
          </div>
        </div> */}
        <div className="bg-transparent border-2  rounded-md w-72 h-96 border-primary">
          <h1 className="text-center pt-4 text-xl text-gray-700 font-bold capitalize CustomtextSizeSubTitle">
            Gift shop Officer
          </h1>
          <div className="flex items-center flex-col mt-4 space-y-4">
            <img
              src={Ephrem}
              alt=""
              className="rounded-full object-cover w-32 h-32"
            />
            <div className="text-xl text-blue-400 font-bold">
              <h1>Ephrem</h1>
              <h1 className="uppercase">Ntihinyuzwa</h1>
            </div>
          </div>
          <div className="w-full mt-4">
            <div className="mx-4 p-2 bg-primary text-white flex flex-col space-y-1 rounded">
              <p>
                <PhoneIcon style={{ color: "white", fontSize: "18px" }} />{" "}
                <span> +250788851849</span>
              </p>
              <p>
                <EmailIcon style={{ color: "white", fontSize: "18px" }} />{" "}
                <span>ntihinyuzwaephrem@gmail.com</span>
              </p>
              <p>
                <TwitterIcon style={{ color: "#0F484D", fontSize: "18px" }} />{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
