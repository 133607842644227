import React from "react";
import Expore from "../components/Expore";
import Home from "../components/Home";
import Layout from "../components/layout/Layout";
import Visit from "../components/Visit";
import OurTeam from "../components/ourTeam/OurTeam";

const Icc = () => {
  return (
    <Layout>
      <Home />
      <div className="max-w-7xl m-auto">
        <Expore />
        <Visit />
        <OurTeam />
      </div>
    </Layout>
  );
};
export default Icc;
