// /* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
// import { Links } from "../utils/data/Links";
import { Link } from "react-router-dom";
import Image from "../assets/images/logo.png";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const mobileDropdownRef = useRef(null);
  const handleClick = () => setOpen(!open);

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isMobileDropdownOpen, setMobileDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const toggleMobileDropdown = () => {
    setMobileDropdownOpen(!isMobileDropdownOpen);
  };

  const closeDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdown);

    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, []);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      if (scrollTop > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <nav
        className={`border-gray-200 ${
          isScrolled ? "bg-primary" : "bg-transparent"
        } transition-all duration-1000 ease-in-out`}
      >
        <div className="max-w-7xl 2xl:px-4 flex flex-wrap items-center justify-between mx-auto px-4 py-6">
          <Link to={"/"} className="flex items-center">
            <img src={Image} className="w-20 h-20 rounded-full" alt="Logo" />
          </Link>
          <button
            data-collapse-toggle="navbar-dropdown"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg lg:hidden bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            onClick={handleClick}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div
            className="hidden w-full lg:block md:w-auto"
            id="navbar-dropdown"
          >
            <ul className="flex flex-col font-bold font-poppins text-lg text-white capitalize p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row md:items-center md:space-x-14 md:mt-0 md:border-0">
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li className="relative">
                <button
                  onClick={toggleDropdown}
                  className="flex items-center justify-between"
                >
                  Exhibitions
                  <svg
                    className="w-2.5 h-2.5 ml-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>
                <div
                  className={`z-10 ${
                    isDropdownOpen ? "" : "hidden"
                  } font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 absolute`}
                  ref={dropdownRef}
                >
                  <ul
                    className="py-2 text-sm text-gray-700"
                    aria-labelledby="dropdownLargeButton"
                  >
                    <li>
                      <Link
                        to={"/exhibition/permanent"}
                        className="block px-4 py-2 hover:bg-gray-100 hover:text-blue-700"
                      >
                        Permanent
                      </Link>
                    </li>
                    <li onClick={closeDropdown}>
                      <Link
                        to={"/exhibition/temporary"}
                        className="block px-4 py-2 hover:bg-gray-100 hover:text-blue-700"
                      >
                        Temporary
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <Link to={"/arts&craft"}>Arts and craft</Link>
              </li>
              <li>
                <Link to={"/library"}>Library</Link>
              </li>
              <li>
                <Link to={"/contact"}>Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <ul
        className={
          !open
            ? "hidden"
            : "absolute bg-primary w-full justify-end px-8 pb-4 font-poppins right-0 z-10 transition-all duration-500 ease-in lg:hidden"
        }
      >
        <div className="flex md:flex-row md:space-x-12 flex-col md:px-6 font-bold font-poppins text-sm py-2 text-white capitalize">
          <li>
            <Link
              to={"/"}
              className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent"
            >
              Home
            </Link>
          </li>
          <li className="relative">
            <button
              onClick={toggleMobileDropdown}
              className="flex items-center justify-between w-full py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto dark:text-white md:dark:hover:text-blue-500 dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent"
            >
              Exhibitions
              <svg
                className="w-2.5 h-2.5 ml-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>
            <div
              className={`z-10 ${
                isMobileDropdownOpen ? "" : "hidden"
              } font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:divide-gray-600  absolute`}
              ref={mobileDropdownRef}
            >
              <ul
                className="py-2 text-sm text-gray-700"
                aria-labelledby="dropdownLargeButton"
              >
                <li>
                  <Link
                    to={"/exhibition/permanent"}
                    className="block px-4 py-2 hover:bg-gray-100 hover:text-blue-700"
                  >
                    Permanent
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/exhibition/temporary"}
                    className="block px-4 py-2 hover:bg-gray-100 hover:text-blue-700"
                  >
                    Temporary
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <Link
              to={"/arts&craft"}
              className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
            >
              Arts and craft
            </Link>
          </li>
          <li>
            <Link
              to={"/library"}
              className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
            >
              Library
            </Link>
          </li>
          <li className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
            <Link to={"/contact"}>Contact Us</Link>
          </li>
        </div>
      </ul>
    </>
  );
};

export default Navbar;
